import React, { useState, useEffect, useCallback  } from 'react';
import { useParams, useNavigate, useLocation  } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { getLeagueById, updateLeague, League, getContentByType, verifyLeaguePassword, uploadImage, fetchYahooLeagueData, updateYahooLeagueId, getYahooLeagueData } from '../services/firebase';
import Footer from '../components/Footer';
import DeadbeatTracker from '../components/DeadbeatTracker';
import { Pencil, AlertCircle } from 'lucide-react';
import { useYahooAuth } from '../contexts/YahooAuthContext';
import { YahooLeague } from '../types/yahoo';
import {getDoc, doc} from 'firebase/firestore'; 
import { db } from '../services/firebase';
import Navbar from '../components/Navbar';
import WeeklyChallenge from '../components/WeeklyChallengeDisplay';

const LeaguePage: React.FC = () => {
  const { yahooIsAuthenticated, yahooLogin, yahooLogout, checkYahooAuth, yahooToken, refreshYahooToken } = useYahooAuth();
  const { id } = useParams<{ id: string }>();
  const { user, userRole } = useAuth();
  const { theme } = useTheme();
  const [league, setLeague] = useState<League | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedLeague, setEditedLeague] = useState<League | null>(null);
  const [punishments, setPunishments] = useState<Array<{ id: string; title: string }>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [selectedLeagueImage, setSelectedLeagueImage] = useState<File | null>(null);
  const [selectedLoserImage, setSelectedLoserImage] = useState<File | null>(null);
  const [leagueImagePreview, setLeagueImagePreview] = useState<string | null>(null);
  const [loserImagePreview, setLoserImagePreview] = useState<string | null>(null);
  const [selectedBannerImage, setSelectedBannerImage] = useState<File | null>(null);
  const [bannerImagePreview, setBannerImagePreview] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationError, setVerificationError] = useState<string | null>(null);
  const [isUserCommissioner, setIsUserCommissioner] = useState(false);
  const [isYahooLinked, setIsYahooLinked] = useState(false);  
  const [yahooLeagues, setYahooLeagues] = useState<YahooLeague[]>([]);
  const [selectedYahooLeague, setSelectedYahooLeague] = useState<string | null>(null);
  const [yahooError, setYahooError] = useState<string | null>(null);  
  const [isYahooLinking, setIsYahooLinking] = useState(false);
  const [yahooLeagueSelected, setYahooLeagueSelected] = useState(false);
  const [isYahooLeagueSelectionPending, setIsYahooLeagueSelectionPending] = useState(false);

  useEffect(() => {
    const checkAndUpdateYahooAuth = async () => {
      if (league?.yahooLeagueId && !yahooIsAuthenticated) {
        await checkYahooAuth();
        if (yahooIsAuthenticated && yahooToken) {
          try {
            const yahooLeagueData = await getYahooLeagueData(league.yahooLeagueId, yahooToken, refreshYahooToken);
            if (yahooLeagueData) {
              setLeague(prevLeague => ({
                ...prevLeague!,
                yahooLeague: yahooLeagueData
              }));
            }
          } catch (error) {
            console.error('Failed to fetch Yahoo league data:', error);
            setError('Failed to fetch updated Yahoo league data');
          }
        }
      }
    };
  
    checkAndUpdateYahooAuth();
  }, [league, yahooIsAuthenticated, checkYahooAuth, yahooToken, refreshYahooToken]);

  useEffect(() => {
    if (id) {
      fetchLeague();
      fetchPunishments();
    }
  }, [id]);

  useEffect(() => {
    if (league) {
      setLoserImagePreview(league.mostRecentLoser?.imageUrl ?? null);
      setBannerImagePreview(league.bannerImageUrl ?? null);
    }
  }, [league]);

  

  useEffect(() => {
    checkCommissionerStatus();
  }, [league, user, userRole]);

 
  useEffect(() => {
    const fetchYahooLeagues = async () => {
      if (yahooIsAuthenticated && yahooToken && (isYahooLinking || isYahooLeagueSelectionPending)) {
        try {
          const leagues = await fetchYahooLeagueData(yahooToken, refreshYahooToken);
          setYahooLeagues(leagues);
          if (leagues.length === 0) {
            setYahooError('No Yahoo leagues found for this account. Please create a Yahoo fantasy football league first.');
          } else {
            setYahooError(null);
            setIsYahooLeagueSelectionPending(true);
          }
        } catch (error) {
          console.error('Error fetching Yahoo leagues:', error);
          setYahooError('Failed to fetch Yahoo leagues. Please try again later.');
        } finally {
          setIsYahooLinking(false);
        }
      }
    };

    fetchYahooLeagues();
  }, [yahooIsAuthenticated, yahooToken, refreshYahooToken, isYahooLinking, isYahooLeagueSelectionPending]);

  useEffect(() => {
    const linkYahooLeague = async () => {
      if (yahooIsAuthenticated && league && !league.yahooLeagueId && league.id) {
        try {
          const yahooLeagues = await fetchYahooLeagueData(yahooToken, refreshYahooToken);
          
          if (yahooLeagues && yahooLeagues.length > 0) {
            const yahooLeagueId = yahooLeagues[0].league_id;
            await updateYahooLeagueId(league.id, yahooLeagueId);
            setLeague(prevLeague => prevLeague ? {...prevLeague, yahooLeagueId} : null);
            console.log('Yahoo league linked successfully');
          } else {
            console.log('No Yahoo leagues found for this account');
            setError('No Yahoo leagues found for this account. Please create a Yahoo fantasy football league first.');
          }
        } catch (error) {
          console.error('Error linking Yahoo league:', error);
          setError('Failed to link Yahoo league. Please try again later.');
        }
      } else if (yahooIsAuthenticated && (!league || !league.id)) {
        console.error('Cannot link Yahoo league: League or league ID is undefined');
        setError('Failed to link Yahoo league: League information is missing');
      }
    };
  
    if (yahooIsAuthenticated && league && !league.yahooLeagueId) {
      linkYahooLeague();
    }
  }, [yahooIsAuthenticated, league, yahooToken, refreshYahooToken]);
  
  useEffect(() => {
    if (id) {
      localStorage.setItem('yahoo_auth_redirect', `/league/${id}`);
    }
    checkYahooAuth();
  }, [id, checkYahooAuth]);

  const checkCommissionerStatus = () => {
    if (user && league) {
      const isCommissioner = league.commissionerId === user.uid;
      setIsUserCommissioner(isCommissioner || userRole === 'admin');
    }
  };
  const handleYahooLeagueSelect = async (yahooLeagueId: string) => {
    console.log('Selecting Yahoo league:', yahooLeagueId);
    setSelectedYahooLeague(yahooLeagueId);
    
    if (!league?.id) {
      setError('Unable to link Yahoo league. Local league ID is missing.');
      return;
    }
  
    try {
      const yahooLeagueDoc = await getDoc(doc(db, 'yahooLeagues', yahooLeagueId));
      
      if (!yahooLeagueDoc.exists()) {
        setError('Selected Yahoo league data not found in Firebase. Please try again.');
        return;
      }
  
      const yahooLeagueData = yahooLeagueDoc.data() as YahooLeague;
      console.log('Yahoo League Data from Firebase:', yahooLeagueData);
  
      const teams = yahooLeagueData.teams || [];
      console.log('Teams:', teams);
  
      const currentLeagueDues = editedLeague?.deadbeatTracker?.find(entry => entry?.balance > 0)?.balance ?? 100;
  
      const deadbeatTracker: League['deadbeatTracker'] = teams.map(team => ({
        team: team.name,
        status: 'DEADBEAT',
        balance: currentLeagueDues,
        datePaid: ''
      }));
  
      console.log('Created Deadbeat Tracker:', deadbeatTracker);
  
      const updatedLeague: Partial<League> = {
        yahooLeagueId: yahooLeagueId,
        yahooLeague: yahooLeagueData,
        name: yahooLeagueData.name,
        yearStarted: yahooLeagueData.yearStarted,
        leagueUrl: yahooLeagueData.url,
        deadbeatTracker: deadbeatTracker
      };
  
      await updateLeague(league.id, updatedLeague);
      
      setLeague(prevLeague => ({
        ...prevLeague!,
        ...updatedLeague
      }));
  
      setEditedLeague(prevLeague => ({
        ...prevLeague!,
        ...updatedLeague
      }));
  
      setIsYahooLeagueSelectionPending(false);
      setError(null);
      console.log('Yahoo league linked successfully');
      console.log('Updated League:', updatedLeague);
    } catch (error) {
      console.error('Error updating league with Yahoo data:', error);
      setError('Failed to update league with Yahoo data. Please try again.');
    }
  };

  const handleYahooLogout = async () => {
    try {
      await yahooLogout();
      setSelectedYahooLeague(null);
      setYahooLeagues([]);
      setIsYahooLinked(false);
      setError(null);
      console.log('Yahoo account unlinked successfully');
    } catch (error) {
      console.error('Error unlinking Yahoo account:', error);
      setError('Failed to unlink Yahoo account. Please try again.');
    }
  };


  const fetchLeague = async () => {
    if (id) {
      try {
        setIsLoading(true);
        const fetchedLeague = await getLeagueById(id);
        if (fetchedLeague) {
          setLeague(fetchedLeague);
          setEditedLeague(fetchedLeague);
          setIsPasswordProtected(fetchedLeague.isPasswordProtected);
          setIsPasswordCorrect(!fetchedLeague.isPasswordProtected);
          setIsYahooLinked(!!fetchedLeague.yahooLeagueId);
          
          if (fetchedLeague.yahooLeagueId && fetchedLeague.yahooLeague) {
            setSelectedYahooLeague(fetchedLeague.yahooLeagueId);
            setYahooLeagues([fetchedLeague.yahooLeague]);
          }
        } else {
          setError('League not found');
        }
      } catch (error) {
        console.error('Error fetching league:', error);
        setError('Failed to fetch league data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchPunishments = async () => {
    try {
      const punishmentContent = await getContentByType('punishment');
      const formattedPunishments = punishmentContent.map(item => ({
        id: item.id,
        title: item.title,
        type: item.type,
      }));
      setPunishments(formattedPunishments);
    } catch (err) {
      console.error('Error fetching punishments:', err);
      setError('Failed to fetch punishments');
    }
  };


  const handlePasswordSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (id && password) {
      setIsVerifying(true);
      setVerificationError(null);
      try {
        const isCorrect = await verifyLeaguePassword(id, password);
        if (isCorrect) {
          setIsPasswordCorrect(true);
        } else {
          setVerificationError('Incorrect password');
        }
      } catch (error) {
        console.error('Error verifying password:', error);
        setVerificationError('Failed to verify password. Please try again.');
      } finally {
        setIsVerifying(false);
      }
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleLeagueChange = (updatedLeague: League) => {
    setEditedLeague(updatedLeague);
  };

  const handleSave = async () => {
    if (id && editedLeague) {
      try {
        let updatedLeague = { ...editedLeague };
  
        if (selectedBannerImage) {
          const bannerImageUrl = await uploadImage(selectedBannerImage);
          updatedLeague.bannerImageUrl = bannerImageUrl;
        }
  
        if (selectedLoserImage) {
          const loserImageUrl = await uploadImage(selectedLoserImage);
          updatedLeague.mostRecentLoser = {
            ...updatedLeague.mostRecentLoser,
            imageUrl: loserImageUrl
          };
        }
  
        if (isPasswordProtected && newPassword) {
          updatedLeague.password = newPassword;
        }
  
        await updateLeague(id, updatedLeague);
        setLeague(updatedLeague);
        setEditedLeague(updatedLeague);
        setIsEditing(false);
        setNewPassword('');
        setSelectedBannerImage(null);
        setSelectedLoserImage(null);
        setBannerImagePreview(updatedLeague.bannerImageUrl ?? null);
        setLoserImagePreview(updatedLeague.mostRecentLoser?.imageUrl ?? null);
      } catch (error) {
        console.error('Error updating league:', error);
        setError('Failed to update league');
      }
    }
  };

  const handleCancel = () => {
    setEditedLeague(league);
    setIsEditing(false);
    setSelectedLeagueImage(null);
    setSelectedLoserImage(null);
    setLeagueImagePreview(league?.imageUrl ?? null);
    setLoserImagePreview(league?.mostRecentLoser?.imageUrl ?? null);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
    section?: keyof League
  ) => {
    const { name, value } = e.target;

    setEditedLeague((prev) => {
      if (!prev) return prev;

      if (section) {
        return {
          ...prev,
          [section]: {
            ...(prev[section] as Record<string, unknown>),
            [name]: value
          }
        };
      } else {
        return {
          ...prev,
          [name]: value
        };
      }
    });
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>, isBannerImage: boolean = false) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const imageUrl = URL.createObjectURL(file);
      if (isBannerImage) {
        setSelectedBannerImage(file);
        setBannerImagePreview(imageUrl);
      } else {
        setSelectedLoserImage(file);
        setLoserImagePreview(imageUrl);
      }
    }
  };





  const handleYahooLink = async () => {
    if (!isUserCommissioner && userRole !== 'admin') {
      setError('Only commissioners and admins can link Yahoo accounts');
      return;
    }
  
    if (yahooIsAuthenticated) {
      try {
        await checkYahooAuth(); // Ensure the token is still valid
        if (isYahooLeagueSelectionPending) {
          setIsYahooLeagueSelectionPending(false);
        } else {
          setIsYahooLeagueSelectionPending(true);
          const leagues = await fetchYahooLeagueData(yahooToken, refreshYahooToken);
          setYahooLeagues(leagues);
          if (leagues.length === 0) {
            setYahooError('No Yahoo leagues found for this account. Please create a Yahoo fantasy football league first.');
          } else {
            setYahooError(null);
          }
        }
      } catch (error) {
        console.error('Error checking Yahoo auth:', error);
        setYahooError('Failed to verify Yahoo authentication. Please try linking your account again.');
        await yahooLogout(); // Force a re-authentication
        yahooLogin();
      }
    } else {
      yahooLogin();
    }
  };


  const getPunishmentUrl = (id: string) => {
    return `https://fantasyloser.com/punishments/${id}`;
  };

  const formatTitle = (key: string): string => {
    return key
      .split(/(?=[A-Z])/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const renderField = (key: string, value: any, section?: keyof League) => {
    const shouldRender = isEditing || (value !== undefined && value !== '' && value !== 0);
    
    if (typeof value === 'object' || Array.isArray(value)) {
      return shouldRender ? (
        <div key={key} className="mb-2">
          <span className="uppercase font-bold text-base sm:text-xl">{formatTitle(key)}:</span> [Complex Data]
        </div>
      ) : null;
    }
  
    if (key === 'leagueUrl') {
      return (
        <div key={key} className={`mb-2 font-source ${shouldRender ? '' : 'hidden'}`}>
          {isEditing ? (
            <>
              <label className="block mt-2 uppercase font-bold text-base sm:text-xl">{formatTitle(key)}:</label>
              <input
                type="text"
                name={key}
                value={value?.toString() ?? ''}
                onChange={(e) => handleInputChange(e, section)}
                className="w-full p-2 text-black rounded"
              />
            </>
          ) : (
            <div className='text-base sm:text-xl flex'>
              <span className="uppercase font-bold inline-block mr-2">{formatTitle(key)}:</span>
              <span className="inline-block max-w-[calc(100%-120px)] truncate" title={value}>
                <a 
                  href={value.startsWith('http') ? value : `https://${value}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800 underline"
                >
                  {value}
                </a>
              </span>
            </div>
          )}
        </div>
      );
    }
  
    return (
      <div key={key} className={`mb-2 font-source ${shouldRender ? '' : 'hidden'}`}>
        {isEditing ? (
          <>
            <label className="block mt-2 uppercase font-bold text-base sm:text-xl">{formatTitle(key)}:</label>
            <input
              type="text"
              name={key}
              value={value?.toString() ?? ''}
              onChange={(e) => handleInputChange(e, section)}
              className="w-full p-2 text-black rounded"
            />
          </>
        ) : (
          <div className='text-base sm:text-xl flex'>
            <span className="uppercase font-bold inline-block mr-2">{formatTitle(key)}:</span>
            <span>{value}</span>
          </div>
        )}
      </div>
    );
  };

  const renderYahooLeagueSelection = () => {
    if (!isYahooLeagueSelectionPending) return null;

    return (
      <div className="mb-4 bg-white p-4 rounded-lg shadow">
        <h3 className="text-xl font-bold mb-2">Select Yahoo League</h3>
        {yahooError && (
          <div className="flex items-center text-red-500 mb-2">
            <AlertCircle size={16} className="mr-2" />
            <span>{yahooError}</span>
          </div>
        )}
        <select
          value={selectedYahooLeague || ''}
          onChange={(e) => handleYahooLeagueSelect(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="">Select a league</option>
          {yahooLeagues.map((league) => (
            <option key={league.league_id} value={league.league_id}>
              {league.name}
            </option>
          ))}
        </select>
      </div>
    );
  };


  
  const renderLeagueProfile = () => (
    <div className="bg-[#18f7b1] p-4 rounded-lg">
      <h2 className="text-3xl font-bold mb-4 text-black italic font-eau">LEAGUE PROFILE</h2>
      <div className="text-black text-base sm:text-xl font-source">
        {isEditing && (
          <div className="mb-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isPasswordProtected}
                onChange={(e) => setIsPasswordProtected(e.target.checked)}
                className="form-checkbox"
              />
              <span className="uppercase font-bold text-base sm:text-xl">PASSWORD PROTECTED</span>
            </label>
            {isPasswordProtected && (
              <div className="mt-2">
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Set new password"
                  className="w-full p-2 border rounded"
                />
              </div>
            )}
          </div>
        )}
        {['name', 'commish', 'yearStarted', 'mostRecentChamp', 'recentLoser', 'leagueUrl'].map(key => 
          renderField(key, isEditing ? editedLeague?.[key as keyof League] : league?.[key as keyof League])
        )}
      </div>
    </div>
  );
  
  const renderPunishment = () => (
    <div className="bg-black p-4 rounded mt-4">
      <h2 className="text-[#18f7b1] text-3xl font-bold mb-4 italic font-eau">PUNISHMENT TERMS</h2>
      
      <div className="mb-2">
        {isEditing ? (
          <div className="flex flex-wrap items-center">
            <div className="w-full sm:w-2/3 mb-2 sm:mb-0 sm:pr-2">
              <label className="block text-white text-base sm:text-xl font-bold uppercase">SELECTED PUNISHMENT:</label>
              <select
                name="selectedPunishment"
                value={editedLeague?.punishment?.selectedPunishment || ''}
                onChange={(e) => handleInputChange(e, 'punishment')}
                className="w-full p-2 text-black rounded mt-1"
              >
                <option value="">Select a punishment</option>
                {punishments.map((punishment) => (
                  <option key={punishment.id} value={punishment.id}>{punishment.title}</option>
                ))}
              </select>
            </div>
            {editedLeague?.punishment?.selectedPunishment && (
              <div className="w-full sm:w-1/3 sm:pl-2 flex items-end">
                <a
                  href={getPunishmentUrl(editedLeague.punishment.selectedPunishment)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-[#18f7b1] hover:bg-[#333333] text-black hover:text-white font-bold py-2 px-4 rounded inline-block w-full text-center"
                >
                  View Punishment
                </a>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-wrap items-center">
            <p className="text-white text-base sm:text-xl mr-4 mb-2 sm:mb-0">
              <span className="uppercase font-bold">SELECTED PUNISHMENT:</span>{' '}
              {punishments.find(p => p.id === league?.punishment?.selectedPunishment)?.title || 'N/A'}
            </p>
            {league?.punishment?.selectedPunishment && (
              <a
                href={getPunishmentUrl(league.punishment.selectedPunishment)}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#18f7b1] hover:bg-[#333333] text-black hover:text-white font-bold py-2 px-4 rounded inline-block"
              >
                View Punishment
              </a>
            )}
          </div>
        )}
      </div>
  
      <div className="block mt-2 text-white text-base sm:text-xl font-source">
        {renderField('title', isEditing ? editedLeague?.punishment?.title : league?.punishment?.title, 'punishment')}
      </div>
  
      <div className="mb-2">
        {isEditing ? (
          <div>
            <label className="block text-white text-base sm:text-xl font-bold uppercase">CUSTOM PUNISHMENT:</label>
            <textarea
              name="customPunishment"
              value={editedLeague?.punishment?.customPunishment || ''}
              onChange={(e) => handleInputChange(e, 'punishment')}
              className="w-full p-2 text-black rounded mt-1"
              rows={3}
            />
          </div>
        ) : (
          <div className="text-white text-base sm:text-xl">
            <span className="uppercase font-bold">CUSTOM PUNISHMENT:</span>{' '}
            {league?.punishment?.customPunishment || 'N/A'}
          </div>
        )}
      </div>
  
      {isEditing ? (
        <div className="mb-2">
          <label className="block mt-2 text-white text-base sm:text-xl font-bold uppercase">PUNISHMENT STATUS:</label>
          <select
            name="status"
            value={editedLeague?.punishment?.status || 'pending'}
            onChange={(e) => handleInputChange(e, 'punishment')}
            className="w-full p-2 text-black rounded mt-1"
          >
            <option value="approved">Approved</option>
            <option value="denied">Denied</option>
            <option value="pending">Pending</option>
          </select>
        </div>
      ) : (
        <div className="mb-2">
          <p className="text-white text-base sm:text-xl">
            <span className="uppercase font-bold">PUNISHMENT STATUS:</span>{' '}
            {league?.punishment?.status || 'N/A'}
          </p>
        </div>
      )}
    </div>
  );
  
  const renderMostRecentLoser = () => (
    <div className="bg-[#18f7b1] p-4 rounded-lg mt-4">
      <h2 className="text-3xl font-bold mb-4 text-black italic font-eau">MOST RECENT LOSER</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="text-black text-base sm:text-xl font-source">
          {['name', 'fantasySeason', 'record', 'winPercentage', 'totalSeasonPoints', 'totalPointsRanking'].map(key => 
            renderField(
              key, 
              isEditing 
                ? editedLeague?.mostRecentLoser?.[key as keyof typeof editedLeague.mostRecentLoser] 
                : league?.mostRecentLoser?.[key as keyof typeof league.mostRecentLoser],
              'mostRecentLoser'
            )
          )}
        </div>
        <div className="flex flex-col items-center justify-center">
          {isEditing ? (
            <div className="w-full">
              <label className="block mt-2 mb-2 uppercase font-bold text-base sm:text-xl">LOSER IMAGE:</label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, false)}
                className="w-full p-2 text-black mb-2"
              />
              {loserImagePreview && (
                <div className="w-full h-48 bg-transparent rounded-lg overflow-hidden">
                  <img
                    src={loserImagePreview}
                    alt="Most Recent Loser"
                    className="w-full h-full object-contain"
                  />
                </div>
              )}
            </div>
          ) : (
            loserImagePreview && (
              <div className="w-full h-[15rem] bg-transparent rounded-lg overflow-hidden">
                <img
                  src={loserImagePreview}
                  alt="Most Recent Loser"
                  className="w-full h-full object-contain"
                />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!league) {
    return <div>League not found</div>;
  }

  if (league.isPasswordProtected && !isPasswordCorrect) {
    return (
      <div className="flex flex-col min-h-screen bg-white text-black">
        <Navbar />
      <main className="flex-grow container mx-auto px-4 py-8 mt-16">
        <h1 className="text-4xl font-bold mb-4 text-center">{league.name}</h1>
        <p className="text-center mb-4">This league is password protected.</p>
        <form onSubmit={handlePasswordSubmit} className="max-w-sm mx-auto">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            className="w-full p-2 border rounded mb-2"
            disabled={isVerifying}
          />
          <button
            type="submit"
            className={`w-full bg-[#18f7b1] hover:bg-black text-black hover:text-white px-4 py-2 rounded-lg ${isVerifying ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isVerifying}
          >
            {isVerifying ? 'Verifying...' : 'Submit'}
          </button>
        </form>
        {verificationError && (
          <p className="text-red-500 text-center mt-2">{verificationError}</p>
        )}
        {error && <p className="text-red-500 text-center mt-2">{error}</p>}
      </main>
      <Footer />
    </div>
    );
  }

  const canEdit = user && league.commissionerId === user.uid;

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-white text-black' : 'bg-white text-black'}`}>
      <Navbar />
      <main className="container mx-auto px-4 py-8 max-w-4xl relative">
      {error && (
  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4 mb-4" role="alert">
    <strong className="font-bold">Error: </strong>
    <span className="block sm:inline">{error}</span>
  </div>
)}
        {canEdit && !isEditing && (
          <button 
            onClick={handleEdit} 
            className="absolute top-2 right-4 bg-[#18f7b1] hover:bg-black text-black hover:text-white px-3 py-2 rounded-full transition-colors duration-300 flex items-center shadow-md mt-[10%]"
            aria-label="Edit League"
          >
            <Pencil size={20} />
            <span className="ml-2 hidden sm:inline">Edit League</span>
          </button>
        )}
        
        <div className="mb-8 w-full h-64 relative mt-[12%]">
          {isEditing ? (
            <div className="mb-4">
              <label className="block mb-2">Banner Image:</label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e, true)}
                className="w-full p-2 text-black mb-2"
              />
            </div>
          ) : null}
          {bannerImagePreview && (
            <div className="w-full h-full bg-transparent overflow-hidden">
              <img
                src={bannerImagePreview}
                alt="League Banner"
                className="w-full h-full object-contain"
              />
            </div>
          )}
        </div>
  
        <h1 className="text-5xl uppercase mb-4 text-center font-source">{league.name}</h1>
        {yahooIsAuthenticated && !league?.yahooLeagueId && (
        <div>
          <h2>Select Yahoo League to Link</h2>
          <select onChange={(e) => handleYahooLeagueSelect(e.target.value)} value={selectedYahooLeague || ''}>
            <option value="">Select a league</option>
            {yahooLeagues.map((yahooLeague) => (
              <option key={yahooLeague.league_id} value={yahooLeague.league_id}>
                {yahooLeague.name}
              </option>
            ))}
          </select>
        </div>
      )}

        <div className="space-y-4">

        {league.yahooLeague && yahooToken && (
  <WeeklyChallenge 
    yahooLeague={league.yahooLeague} 
    yahooToken={yahooToken}
    refreshYahooToken={refreshYahooToken}
  />
)}
          {renderLeagueProfile()}
          {renderPunishment()}
          {renderMostRecentLoser()}
  
          {editedLeague && (
            <DeadbeatTracker 
              league={editedLeague} 
              isEditing={isEditing} 
              onLeagueChange={handleLeagueChange}
            />
          )}

{renderYahooLeagueSelection()}

{yahooIsAuthenticated ? (
  <div>
    <button
      onClick={handleYahooLink}
      className="mt-4 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded mr-2"
    >
      {isYahooLeagueSelectionPending ? 'Cancel Selection' : (league?.yahooLeagueId ? 'Change Yahoo League' : 'Select Yahoo League')}
    </button>
    <button
      onClick={handleYahooLogout}
      className="mt-4 bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
    >
      Unlink Yahoo Account
    </button>
  </div>
) : (
  <button
    onClick={handleYahooLink}
    className="mt-4 bg-[#18f7b1] hover:bg-[#333333] text-white font-bold py-2 px-4 rounded"
    disabled={!(isUserCommissioner || userRole === 'admin')}
  >
    Link Yahoo Account
  </button>
)}

{error && (
  <div className="mt-2 text-red-500">
    {error}
  </div>
)}

          {isEditing && (
            <div className="mt-8 flex justify-end space-x-4">
              <button 
                onClick={handleSave} 
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg transition duration-300"
              >
                Save Changes
              </button>
              <button 
                onClick={handleCancel} 
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg transition duration-300"
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default LeaguePage;
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../services/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import axios from 'axios';

interface YahooAuthContextType {
  yahooIsAuthenticated: boolean;
  yahooLogin: () => void;
  yahooLogout: () => Promise<void>;
  yahooToken: string | null;
  refreshYahooToken: () => Promise<string>;
  checkYahooAuth: () => Promise<void>;
  updateYahooAuthState: (token: string, refreshToken: string, expiresIn: number, tokenType: string) => Promise<void>;
}

const YahooAuthContext = createContext<YahooAuthContextType | undefined>(undefined);

export const YahooAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [yahooIsAuthenticated, setYahooIsAuthenticated] = useState<boolean>(false);
  const [yahooToken, setYahooToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [tokenExpiration, setTokenExpiration] = useState<number | null>(null);
  const [tokenType, setTokenType] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        checkYahooAuth();
      } else {
        setYahooIsAuthenticated(false);
        setYahooToken(null);
        setRefreshToken(null);
        setTokenExpiration(null);
        setTokenType(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (tokenExpiration) {
      const timeUntilExpiration = tokenExpiration - Date.now();
      if (timeUntilExpiration > 0) {
        const timer = setTimeout(() => {
          refreshYahooToken();
        }, timeUntilExpiration - 60000); // Refresh 1 minute before expiration
        return () => clearTimeout(timer);
      }
    }
  }, [tokenExpiration]);
  
  useEffect(() => {
    const interval = setInterval(periodicTokenCheck, 60000); // Check every minute
    return () => clearInterval(interval);
  }, [yahooToken, tokenExpiration]);

  const periodicTokenCheck = async () => {
    if (yahooToken && tokenExpiration) {
      const now = Date.now();
      if (tokenExpiration - now < 300000) { // Less than 5 minutes until expiration
        try {
          await refreshYahooToken();
        } catch (error) {
          console.error('Failed to refresh token:', error);
          setYahooIsAuthenticated(false);
        }
      }
    }
  };

  const checkYahooAuth = async () => {
    if (auth.currentUser) {
      const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
      const yahooTokenData = userDoc.data()?.yahooToken;
      if (yahooTokenData) {
        if (yahooTokenData.expiresAt > Date.now()) {
          setYahooToken(yahooTokenData.token);
          setRefreshToken(yahooTokenData.refreshToken);
          setTokenExpiration(yahooTokenData.expiresAt);
          setTokenType(yahooTokenData.tokenType);
          setYahooIsAuthenticated(true);
        } else {
          // Token has expired, attempt to refresh
          try {
            await refreshYahooToken();
          } catch (error) {
            console.error('Failed to refresh token:', error);
            setYahooIsAuthenticated(false);
          }
        }
      } else {
        setYahooIsAuthenticated(false);
      }
    }
  };

  const refreshYahooToken = async () => {
    if (refreshToken) {
      try {
        const response = await axios.post('/.netlify/functions/yahoo-token-refresh', {
          refresh_token: refreshToken
        });
        
        const { access_token, refresh_token, expires_in, token_type } = response.data;
        await updateYahooAuthState(access_token, refresh_token, expires_in, token_type);
        return access_token;
      } catch (error) {
        console.error('Error refreshing Yahoo token:', error);
        setYahooIsAuthenticated(false);
        throw error;
      }
    }
    throw new Error('No refresh token available');
  };

  const yahooLogin = () => {
    const clientId = process.env.REACT_APP_YAHOO_CLIENT_ID;
    const redirectUri = encodeURIComponent(`${window.location.origin}/yahoo-callback`);
    const state = Math.random().toString(36).substring(7);
    localStorage.setItem('yahoo_oauth_state', state);

    const authUrl = `https://api.login.yahoo.com/oauth2/request_auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&state=${state}&language=en-us`;
  
    window.location.href = authUrl;
  };

  const updateYahooAuthState = async (token: string, refreshToken: string, expiresIn: number, tokenType: string) => {
    if (auth.currentUser) {
      const yahooTokenData = {
        token,
        refreshToken,
        expiresAt: Date.now() + expiresIn * 1000,
        tokenType
      };
      await setDoc(doc(db, 'users', auth.currentUser.uid), { yahooToken: yahooTokenData }, { merge: true });
      setYahooToken(token);
      setRefreshToken(refreshToken);
      setTokenExpiration(yahooTokenData.expiresAt);
      setTokenType(tokenType);
      setYahooIsAuthenticated(true);
    }
  };

  const yahooLogout = async () => {
    if (auth.currentUser) {
      await setDoc(doc(db, 'users', auth.currentUser.uid), { yahooToken: null }, { merge: true });
    }
    setYahooToken(null);
    setRefreshToken(null);
    setTokenExpiration(null);
    setTokenType(null);
    setYahooIsAuthenticated(false);
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or any loading indicator you prefer
  }

  return (
    <YahooAuthContext.Provider value={{ 
      yahooIsAuthenticated, 
      yahooLogin, 
      yahooLogout, 
      yahooToken, 
      refreshYahooToken,
      checkYahooAuth,
      updateYahooAuthState
    }}>
      {children}
    </YahooAuthContext.Provider>
  );
};

export const useYahooAuth = () => {
  const context = useContext(YahooAuthContext);
  if (context === undefined) {
    throw new Error('useYahooAuth must be used within a YahooAuthProvider');
  }
  return context;
};